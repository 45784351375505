import React, { useState } from 'react';
import { Icon, M } from 'react-materialize';

export default function CopyToClipboardIcon(props) {

  const [copySuccess, setCopySuccess] = useState('');


  
  function copyToClipboard(e) {
    //debugger
    //var instance = M.Tooltip.getInstance(e);

    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute('value', props.value);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);



    
    setCopySuccess('Copied!');
  };

  return (<div>
      {
       /* Logical shortcut for only displaying the 
          button if the copy command exists */
       document.queryCommandSupported('copy') &&
       <div>
         <a href="#" onClick={((e) => copyToClipboard(e))}  className="btn-floating btn-small waves-effect waves-light teal tooltipped" data-position="left" data-tooltip="Copied!" title="Click to copy"><i className="material-icons">file_copy</i></a>
  
         {/* <a href="#" onClick={copyToClipboard}  data-position="left" tooltip="I am a tooltip"><Icon className="black-text right">
       file_copy
       </Icon></a>
         <span className="black-text">{copySuccess}</span> */}
       </div>


// <div>
//          <a href="#" onClick={copyToClipboard}  data-position="left" tooltip="I am a tooltip"><Icon className="black-text right">
//        file_copy
//        </Icon></a>
//          <span className="black-text">{copySuccess}</span>
//        </div>
       
      }

    </div>
  );
}