import React, {Component} from 'react';
import { Navbar } from 'react-materialize';
import './App.css';
import { Link, NavLink } from "react-router-dom";


class HeaderNav extends Component {
  render(){
    return <Navbar brand={<Link to="/">{"{swattsware}"}</Link>} alignLinks="right" fixed={true} className="teal" >
 
{/* 
 <div className="outer-construction">
 <div className="under-construction">
    SITE UNDER CONSTRUCTION
    </div>
 </div> */}
    


    <NavLink to="/gousto">Test</NavLink>
    <NavLink to="/contact">Contact</NavLink>
    </Navbar>   
  }
}


export default HeaderNav;
