import React, {Component} from 'react';
import { Collection, CollectionItem, Row, Col, Icon } from 'react-materialize';
import '../App.css';
import  CopyToClipboardIcon  from '../CopyToClipboard'


function ContactDetailsItem(props) {
  
  return <CollectionItem className="valign-wrapper">

  <div className="valign-wrapper">
  <Icon className="circle teal icon-margin-right">
  {props.contactDetail.icon}
  </Icon>
  </div>
  <div className="black-text">{props.contactDetail.value}</div>
  <div className="col s1">
  <CopyToClipboardIcon value={props.contactDetail.value}/>
</div>

  </CollectionItem>
  ;
}

class Contact extends Component {
  constructor(props){
    super(props);
    this.state = {hasLoaded: false
    };
  }

  componentDidMount(){
   
    setTimeout(function() { //Start the timer
      this.setState({hasLoaded: true}) // show contact card
  }.bind(this), 250)

    
  }

  
  render(){
    const contactDetails = [{
      id: 1,
      type: "email",
      value: "steve@swattsware.com",
      icon: "alternate_email" 
    },
    {
      id: 2,
      type: "phone",
      value: "07733 231 471",
      icon: "phone_in_talk" 
    }
  ];


    const contactDetailsItems = contactDetails.map((contactDetail) =>
    <ContactDetailsItem key={contactDetail.id} contactDetail={contactDetail} />);

    return <div className={this.state.hasLoaded ? 'row scale-transition' : 'row scale-transition scale-out'} >

       <div className="col s6 offset-s3">
      <div className="card blue-grey darken-1">
        <div className="card-content white-text">
         <span className="card-title">Stephen Watts</span>
      
        <Collection>
      
         {contactDetailsItems}
      
         </Collection>
      
         </div>

       </div>
    </div>
    </div>
    
  }
}

export default Contact;
