import React, { Component } from "react";

class Gousto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    };
  }

  componentDidMount() {
    fetch("https://mealplanner20210319155724.azurewebsites.net/api/GetMenu")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.items,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
            <h4>Gousto Menu</h4>
          <ul>
              <li>Calories - Name</li>
            {items.map((item) => (
              <li key={item.id}>
                {item.calories} - {item.name}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  }
}

export default Gousto;
