import React, { Component } from "react";
import { Container, Button } from "react-materialize";
import "./App.css";
import Contact from "./Contact/Contact";
import Gousto from "./Test/Gousto";
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router";

class Home extends Component {
  render() {
    return (
      <div>
        <h3>
          Stephen Watts <br />
          <small>Contract Developer</small>
        </h3>
        {/* <h5>Please visit my Linkedin Page while this site is built</h5> */}
        <div></div>

        <div className="col s6 offset-s3">
          <div className="card-panel teal ">
            <div
              className="LI-profile-badge"
              data-version="v1"
              data-size="large"
              data-locale="en_US"
              data-type="horizontal"
              data-theme="dark"
              data-vanity="stevewatts84"
            >
              <a
                className="LI-simple-link"
                href="https://uk.linkedin.com/in/stevewatts84?trk=profile-badge"
                target="_blank"
              >
                Stephen Watts
              </a>
            </div>
            <div className="card-content">
              <p className="white-text">
                Please visit my Linkedin Page while this site is built
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// <div className="card-panel hoverable"> Hoverable Card Panel</div>

// <a className="btn-floating pulse"><i className="material-icons">menu</i></a>

// <div className="col s12 m2">
//   <p className="z-depth-2">z-depth-2</p>
// </div>

// {/* <!-- Scaled in --> */}
// <a id="scale-demo" href="#!" className="btn-floating btn-large scale-transition">
// <i className="material-icons">add</i>
// </a>

// {/* <!-- Scaled out --> */}
// <a id="scale-demo" href="#!" className="btn-floating btn-large scale-transition scale-out">
// <i className="material-icons">add</i>
// </a>

// <a class="btn tooltipped" data-position="bottom" data-tooltip="I am a tooltip">Hover me!</a>

// <Button className="col l2 offset-l1 offset-s4 s4" waves="ligth" tooltip="I am a tooltip">
// BOTTOM
// </Button>

class App extends Component {
  render() {
    return (
      <Container className="main-container">
        <Route path="/contact" component={Contact} />
        
        <Route path="/" component={Home} />
        <Route path="/gousto" component={Gousto} />
        {/* <Route component={NoMatch}/> */}
      </Container>
    );
  }
}

export default App;
