import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import HeaderNav from './Header';
import App from './App';
import FooterNav from './Footer';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter  } from "react-router-dom";

ReactDOM.render(<BrowserRouter ><header><HeaderNav /></header> <main className="center-align"><App /></main> <footer><FooterNav /></footer> <script type="text/javascript" src="https://platform.linkedin.com/badges/js/profile.js" async defer></script></BrowserRouter > , document.getElementById('root'));

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
