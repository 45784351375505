import React, { Component } from "react";
import { Footer } from "react-materialize";
import "./App.css";
import { Link, NavLink } from "react-router-dom";

class FooterNav extends Component {
  render() {
    return (
      <Footer
        copyrights={
          <div className="center-align">
            © swattsware ltd.
            <br />
            Company No. 10126436 | Registered in England and Wales
            <br />
            Registered: First Floor, Telecom House, 125-135 Preston Road,
            Brighton, BN1 6AF
          </div>
        }
        className="teal"
      ></Footer>
    );
  }
}

export default FooterNav;
